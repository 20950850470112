@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

body {
  background-color: #f1f5f9;
}

/* OVERRIDE MAP-LIBRE POPUP STYLES */
.maplibregl-popup-tip {
  border: none !important;
}

.maplibregl-popup-content {
  background: transparent !important;
  padding: 0 !important;
  font-family: "Oswald", sans-serif;
}

.maplibregl-popup-close-button {
  display: none !important;
  top: 0 !important;
  right: 0 !important;
}